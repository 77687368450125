<template>
  <Lock helpless>
    <article class="uk-height-viewport uk-flex uk-flex-column uk-flex-middle">
      <SiteHeader class="uk-margin-top"></SiteHeader>
      <section class="uk-width-expand uk-text-left uk-flex-none uk-padding" style="max-width: 600px;">
        <h2 class="uk-text-center">terms of service</h2>
        <dl class="uk-description-list">
          <dt>1. Application Of Terms</dt>
          <dd>
            <p>
1.1. These Terms apply to your use of the Service (as that term is defined below). By completing
the checkout process:
            </p>
            <ol type="a">
              <li>you agree to these Terms; and</li>
              <li>
where your access and use is on behalf of another person (e.g. a company), you confirm
that you are authorised to, and do in fact, agree to these Terms on that person’s behalf
and that, by agreeing to these Terms on that person’s behalf, that person is bound by
these Terms.
              </li>
            </ol>
            <p>
1.2. If you do not agree to these Terms, you are not authorised to access and use the Service, and
you must immediately stop doing so.
            </p>
          </dd>
          <dt>2. Changes</dt>
          <dd>
            <p>
2.1. We may change these Terms at any time by notifying you of the change by email or by posting
a notice on the Website. Unless stated otherwise, any change takes effect from the date set
out in the notice. You are responsible for ensuring you are familiar with the latest Terms. By
continuing to access and use the Service from the date on which the Terms are changed, you
agree to be bound by the changed Terms.
            </p>
            <p>2.2. <strong>These Terms were last updated on 2019-11-07</strong>.</p>
          </dd>
          <dt>3. Interpretation</dt>
          <dd>
            In these Terms:
            <ul class="uk-list">
              <li>
<em>Confidential Information</em> means any information that is not public knowledge and that is
obtained from the other party in the course of, or in connection with, the provision and use of
the Service. Our Confidential Information includes Intellectual Property owned by us (or our
licensors), including the Squirrel Technologies Software. Your Confidential Information includes
the Data.
              </li>
              <li>
<em>Data</em> means all data, content, and information (including personal information) owned,
held, used or created by you or on your behalf that is stored using, or inputted into, the Service.
Fees means the applicable fees set out on our pricing page on the Website at
www.squirreltechnologies.nz or as agreed otherwise in writing between you and us, as may be
updated from time to time in accordance with clause 7.6.
              </li>
              <li>
<em>Force Majeure</em> means an event that is beyond the reasonable control of a party, excluding:
                <ul class="uk-list uk-list-bullet">
                  <li>
an event to the extent that it could have been avoided by a party taking reasonable steps
or reasonable care; or
                  </li>
                  <li>a lack of funds for any reason.</li>
                </ul>
              </li>
              <li><em>including</em> and similar words do not imply any limit.</li>
              <li>
<em>Intellectual Property Rights</em> includes copyright and all rights existing anywhere in the
world conferred under statute, common law or equity relating to inventions (including patents),
registered and unregistered trade marks and designs, circuit layouts, data and databases,
confidential information, know-how, and all other rights resulting from intellectual activity.
Intellectual Property has a consistent meaning, and includes any enhancement, modification or
derivative work of the Intellectual Property.
              </li>
              <li>
<em>Objectionable</em> includes being objectionable, defamatory, obscene, harassing, threatening,
harmful, or unlawful in any way.
              </li>
              <li><em>a party</em> includes that party’s permitted assigns.</li>
              <li>
<em>Permitted Users</em> means your personnel who are authorised to access and use the Service on
your behalf in accordance with clause 5.3.
              </li>
              <li>
<em>a person</em> includes an individual, a body corporate, an association of persons (whether
corporate or not), a trust, a government department, or any other entity.
              </li>
              <li>
<em>personal information</em> means information about an identifiable, living person.
              </li>
              <li>
<em>personnel</em> includes officers, employees, contractors and agents, but a reference to your
personnel does not include us.
              </li>
              <li>
<em>Service</em> means the service having the core functionality described on the Website, as the
Website is updated from time to time.
              </li>
              <li>
<em>Start Date</em> means the date that you paid for the service.
              </li>
              <li>
<em>Squirrel Technologies Software</em> means the software owned by us (and our licensors) that is
used to provide the Service.
              </li>
              <li><em>Terms</em> means these terms titled SaaS terms of use.</li>
              <li>
<em>Underlying Systems</em> means the Squirrel Technologies Software, IT solutions, systems and
networks (including software and hardware) used to provide the Service, including any third party
solutions, systems and networks.
              </li>
              <li>
<em>We, us or our</em> means Squirrel Technologies Limited, company number 7256537.
              </li>
              <li>
<em>Website</em> means the internet site at squirreltechnologies.nz, acornconnect.com, or such
other site notified to you by us.
              </li>
              <li>
<em>Year</em> means a 12-month period starting on the Start Date or the anniversary of that date.
              </li>
              <li>
<em>You or your</em> means you or, if clause 1.1b applies, both you and the other person on whose
behalf you are acting.
              </li>
            </ul>
            <p>Words in the singular include the plural and vice versa.</p>
            <p>
A reference to a statute includes references to regulations, orders or notices made under or in
connection with the statute or regulations and all amendments, replacements or other changes
to any of them.
            </p>
          </dd>
          <dt>4. Provision Of The Service</dt>
          <dd>
            <p>4.1. We must use reasonable efforts to provide the Service:</p>
            <ol type="a">
              <li>in accordance with these Terms and New Zealand law;</li>
              <li>exercising reasonable care, skill and diligence; and</li>
              <li>using suitably skilled, experienced and qualified personnel.</li>
            </ol>
            <p>
4.2. Our provision of the Service to you is non-exclusive. Nothing in these Terms prevents us from
providing the Service to any other person.
            </p>
            <p>
4.3. Subject to clause 4.4, we must use reasonable efforts to ensure the Service is available on a
24/7 basis. However, it is possible that on occasion the Service may be unavailable to permit
maintenance or other development activity to take place, or in the event of Force Majeure. We must
use reasonable efforts to publish on the Website and/or notify you by email advance details of any
unavailability.
            </p>
            <p>
4.4. Through the use of web services and APIs, the Service interoperates with a range of third
party service features. We do not make any warranty or representation on the availability of
those features. Without limiting the previous sentence, if a third party feature provider ceases
to provide that feature or ceases to make that feature available on reasonable terms, we may
cease to make available that feature to you. To avoid doubt, if we exercise our right to cease
the availability of a third party feature, you are not entitled to any refund, discount or other
compensation.
            </p>
          </dd>
          <dt>5. Your Obligations</dt>
          <dd>
            <p>5.1 You and your personnel must:</p>
            <ol type="a">
              <li>
use the Service in accordance with these Terms solely for:
                <ol type="i">
                  <li>your own internal business purposes; and</li>
                  <li>lawful purposes; and</li>
                </ol>
              </li>
              <li>
not resell or make available the Service to any third party, or otherwise commercially
exploit the Service.
              </li>
            </ol>
            <p>5.2. When accessing the Service, you and your personnel must:</p>
            <ol type="a">
              <li>
not impersonate another person or misrepresent authorisation to act on behalf of others
or us;
              </li>
              <li>correctly identify the sender of all electronic transmissions;</li>
              <li>
not attempt to undermine the security or integrity of the Underlying Systems;
              </li>
              <li>
not use, or misuse, the Service in any way which may impair the functionality of the
Underlying Systems or impair the ability of any other user to use the Service;
              </li>
              <li>
not attempt to view, access or copy any material or data other than:
                <ol type="i">
                  <li>that which you are authorised to access; and</li>
                  <li>
to the extent necessary for you to use the Service in accordance with these Terms;
and
                  </li>
                </ol>
              </li>
              <li>
neither use the Service in a manner, nor transmit, input or store any Data, that breaches
any third party right (including Intellectual Property Rights and privacy rights) or is
Objectionable, incorrect or misleading.
              </li>
            </ol>
            <p>
5.3. Without limiting clause 5.2, no individual other than a Permitted User may access or use the
Service. You may authorise any person to be a Permitted User, in which case you must save the
Permitted User’s name and other information that we reasonably require in relation to the Permitted
User in the Underlying System. You must procure each Permitted User’s compliance with clauses 5.1
and 5.2 and any other reasonable condition notified by us to you.
            </p>
            <p>
5.4. A breach of any of these Terms by your personnel (including, to avoid doubt, a Permitted
User) is deemed to be a breach of these Terms by you.
            </p>
            <p>
5.5. You are responsible for procuring all licences, authorisations and consents required for you
and your personnel to use the Service, including to use, store and input Data into, and process and
distribute Data through, the Service.
            </p>
          </dd>
          <dt>6. Data</dt>
          <dd>
            <p>6.1 You acknowledge that:</p>
            <ol type="a">
              <li>
we may require access to the Data to exercise our rights and perform our obligations
under these Terms; and
              </li>
              <li>
to the extent that this is necessary but subject to clause 9, we may authorise a member
or members of our personnel to access the Data for this purpose.
              </li>
            </ol>
            <p>
6.2. You must arrange all consents and approvals that are necessary for us to access the Data as
described in clause 6.1.
            </p>
            <p>6.3. You acknowledge and agree that:</p>
            <ol type="a">
              <li></li>
              <li>we may:
                <ol type="i">
                  <li>
use Data and information about your and your end users’ use of the Services to
generate anonymised and aggregated statistical and analytical data (Analytical
Data); and
                </li>
                  <li>
use Analytical Data for our internal research and product development purposes
and to conduct statistical analysis and identify trends and insights; and
                  </li>
                  <li>supply Analytical Data to third parties;</li>
                </ol>
              </li>
              <li>
our rights under clause 6.3a above will survive termination of expiry of the Agreement;
and
              </li>
              <li>
title to, and all Intellectual Property Rights in, Analytical Data is and remains our property.
              </li>
            </ol>
            <p>
6.4. You acknowledge and agree that to the extent Data contains personal information, in
collecting, holding and processing that information through the Service, we are acting as your
agent for the purposes of the Privacy Act 1993 and any other applicable privacy law. You must
obtain all necessary consents from the relevant individual to enable us to collect, use, hold
and process that information in accordance with these Terms.
            </p>
            <p>
6.5. While we will take standard industry measures to back up all Data stored using the Service, you
agree to keep a separate back-up copy of all Data uploaded by you onto the Service.
            </p>
            <p>
6.6. You agree that we may store Data (including any personal information) in secure servers in
the European Union, the United Kingdom, and the United States of America and may access that Data
(including any personal information) in the European Union, the United Kingdom, and the United
States of America and New Zealand from time to time.
            </p>
            <p>
6.7. You indemnify us against any liability, claim, proceeding, cost, expense (including the actual
legal fees charged by our solicitors) and loss of any kind arising from any actual or alleged
claim by a third party that any Data infringes the rights of that third party (including
Intellectual Property Rights and privacy rights) or that the Data is Objectionable, incorrect or
misleading.
            </p>
          </dd>
          <dt>7. Fees</dt>
          <dd>
            <p>7.1 You must pay us the Fees.</p>
            <p>
7.2. We will provide you with valid tax invoices on a monthly basis prior to the due date for
payment if you have selected a subscription option.
            </p>
            <p>7.3. The Fees include GST, VAT, or other relevant sales taxes.</p>
            <p>7.4. You must pay the Fees:</p>
            <ol type="a">
              <li>
in accordance with the payment terms set out on our pricing page on the Website; and
              </li>
              <li>electronically in cleared funds without any set off or deduction.</li>
            </ol>
            <p>
7.5. We may charge interest on overdue amounts. Interest will be calculated from the due date to
the date of payment (both inclusive) at an annual percentage rate equal to the corporate
overdraft reference rate (monthly charging cycle) applied by our primary trading bank as at the
due date (or, if our primary trading bank ceases to quote that rate, then the rate which in the
opinion of the bank is equivalent to that rate in respect of similar overdraft accommodation
expressed as a percentage) plus 2% per annum.
            </p>
            <p>
7.6. We may increase the Fees by giving at least 30 days’ notice. If you do not wish to pay the
increased Fees, you may terminate these Terms and your right to access and use the Service
on no less than 10 days’ notice, provided the notice is received by us before the effective date
of the Fee increase. If you do not terminate these Terms and your right to access and use the
Service in accordance with this clause, you are deemed to have accepted the increased Fees.
            </p>
          </dd>
          <dt>8. Intellectual Property</dt>
          <dd>
            <p>
8.1. Subject to clause 8.2, title to, and all Intellectual Property Rights in, the Service, the
Website, and all Underlying Systems is and remains our property (and our licensors’ property). You
must not contest or dispute that ownership, or the validity of those Intellectual Property Rights.
            </p>
            <p>
8.2. Title to, and all Intellectual Property Rights in, the Data (as between the parties) remains
your property. You grant us a worldwide, non-exclusive, fully paid up, transferable, irrevocable
licence to use, store, copy, modify, make available and communicate the Data for any purpose
in connection with the exercise of our rights and performance of our obligations in accordance
with these Terms.
            </p>
            <p>
8.3. To the extent not owned by us, you grant us a royalty-free, transferable, irrevocable and
perpetual licence to use for our own business purposes any know-how, techniques, ideas,
methodologies, and similar Intellectual Property used by us in the provision of the Services.
            </p>
            <p>
8.4. If you provide us with ideas, comments or suggestions relating to the Service or Underlying
Systems (together feedback):
            </p>
            <ol type="a">
              <li>
all Intellectual Property Rights in that feedback, and anything created as a result of that
feedback (including new material, enhancements, modifications or derivative works), are
owned solely by us; and
              </li>
              <li>we may use or disclose the feedback for any purpose.</li>
            </ol>
          </dd>
          <dt>9. Confidentiality</dt>
          <dd>
            <p>
9.1. Each party must, unless it has the prior written consent of the other party:
            </p>
            <ol type="a">
              <li>
keep confidential at all times the Confidential Information of the other party;
              </li>
              <li>
effect and maintain adequate security measures to safeguard the other party’s
Confidential Information from unauthorised access or use; and
              </li>
              <li>
disclose the other party’s Confidential Information to its personnel or professional
advisors on a need to know basis only and, in that case, ensure that any personnel or
professional advisor to whom it discloses the other party’s Confidential Information is
aware of, and complies with, clauses 9.1a and 9.1b.
              </li>
            </ol>
            <p>
9.2. The obligation of confidentiality in clause 9.1 does not apply to any disclosure or use of
Confidential Information:
            </p>
            <ol type="a">
              <li>
for the purpose of performing a party’s obligations, or exercising a party’s rights, under
these Terms;
              </li>
              <li>required by law (including under the rules of any stock exchange);</li>
              <li>
which is publicly available through no fault of the recipient of the Confidential Information
or its personnel;
              </li>
              <li>
which was rightfully received by a party from a third party without restriction and without
breach of any obligation of confidentiality; or
              </li>
              <li>
by us if required as part of a bona fide sale of our business (assets or shares, whether in
whole or in part) to a third party, provided that we enter into a confidentiality agreement
with the third party on terms no less restrictive than this clause 9.
              </li>
            </ol>
          </dd>
          <dt>10. Warranties</dt>
          <dd>
            <p>
10.1 Each party warrants that it has full power and authority to enter into, and perform its
obligations under, these Terms.
            </p>
            <p>10.2. To the maximum extent permitted by law:</p>
            <ol type="a">
              <li>
our warranties are limited to those set out in these Terms, and all other conditions,
guarantees or warranties whether expressed or implied by statute or otherwise (including
any warranty under Part 3 of the Contract and Commercial Law Act 2017) are expressly
excluded and, to the extent that they cannot be excluded, liability for them is limited to
NZD 1,000.00; and
              </li>
              <li>
we make no representation concerning the quality of the Service and do not promise that
the Service will:
                <ol type="i">
                  <li>
meet your requirements or be suitable for a particular purpose, including that the
use of the Service will fulfil or meet any statutory role or responsibility you may
have; or
                  </li>
                  <li>
be secure, free of viruses or other harmful code, uninterrupted or error free.
                  </li>
                </ol>
              </li>
            </ol>
            <p>
10.3 You agree and represent that you are acquiring the Service, and accepting these Terms, for the
purpose of trade. The parties agree that:
            </p>
            <ol type="a">
              <li>
to the maximum extent permissible by law, the Consumer Guarantees Act 1993 and any
other applicable consumer protection legislation does not apply to the supply of the
Service or these Terms; and
              </li>
              <li>it is fair and reasonable that the parties are bound by this clause 10.3.</li>
            </ol>
            <p>
10.4. Where legislation or rule of law implies into these Terms a condition or warranty that cannot
be excluded or modified by contract, the condition or warranty is deemed to be included in these
Terms. However, our liability for any breach of that condition or warranty is limited, at our
option, to:
            </p>
            <ol type="a">
              <li>supplying the Service again; and/or</li>
              <li>paying the costs of having the Service supplied again.</li>
            </ol>
          </dd>
          <dt>11. Liability</dt>
          <dd>
            <p>
11.1. Our maximum aggregate liability under or in connection with these Terms or relating to the
Service, whether in contract, tort (including negligence), breach of statutory duty or otherwise,
must not in any Year exceed an amount equal to the Fees paid by you relating to the Service in the
previous Year (which in the first Year is deemed to be the total Fees paid by you from the Start
Date to the date of the first event giving rise to liability). The cap in this
clause 11.1 includes the cap set out in clause 10.2a.
            </p>
            <p>
11.2. Neither party is liable to the other under or in connection with these Terms or the Service
for any:
            </p>
            <ol type="a">
              <li>
loss of profit, revenue, savings, business, use, data (including Data), and/or goodwill; or
              </li>
              <li>
consequential, indirect, incidental or special damage or loss of any kind.
              </li>
            </ol>
            <p>
11.3. Clauses 11.1 and 11.2 do not apply to limit our liability under or in connection with these
Terms for:
            </p>
            <ol type="a">
              <li>personal injury or death;</li>
              <li>fraud or wilful misconduct; or</li>
              <li>a breach of clause 9.</li>
            </ol>
            <p>11.4. Clause 11.2 does not apply to limit your liability:</p>
            <ol type="a">
              <li>to pay the Fees;</li>
              <li>under the indemnity in clause 6.7; or</li>
              <li>for those matters stated in clause 11.3a to 11.3c.</li>
            </ol>
            <p>
11.5. Neither party will be responsible, liable, or held to be in breach of these Terms for any
failure to perform its obligations under these Terms or otherwise, to the extent that the failure
is caused by the other party failing to comply with its obligations under these Terms, or by the
negligence or misconduct of the other party or its personnel.
            </p>
            <p>
11.6. Each party must take reasonable steps to mitigate any loss or damage, cost or expense it may
suffer or incur arising out of anything done or not done by the other party under or in connection
with these Terms or the Service.
            </p>
          </dd>
          <dt>12. Term, Termination And Suspension</dt>
          <dd>
            <p>
12.1. Unless terminated under this clause 12, these Terms and your right to access and use the
Service:
            </p>
            <ol type="a">
              <li>starts on the Start Date; and</li>
              <li>
continues until the expiry date if you have selected a fixed-term option; or, if you have selected
a subscription option, until a party gives at least 30 days’ notice that these Terms and your
access to and use of the Service will terminate on the expiry of that notice.
              </li>
            </ol>
            <p>
12.2. Subject to clause 7.6, if the subscription option you have selected includes a minimum
initial term, the earliest date for termination under clause 12.1 will be the expiry of that
initial term.
            </p>
            <p>
12.3. Either party may, by notice to the other party, immediately terminate these Terms and
your right to access and use the Service if the other party:
            </p>
            <ol type="a">
              <li>
breaches any material provision of these Terms and the breach is not:
                <ol type="i">
                  <li>
remedied within 10 days of the receipt of a notice from the first party requiring it to
remedy the breach; or
                  </li>
                  <li>capable of being remedied; or</li>
                </ol>
              </li>
              <li>
becomes insolvent, liquidated or bankrupt, has an administrator, receiver, liquidator,
statutory manager, mortgagee’s or chargee’s agent appointed, becomes subject to any
form of insolvency action or external administration, or ceases to continue business for
any reason.
              </li>
            </ol>
            <p>
12.4. You may terminate these Terms and your right to access and use the Service in accordance
with clause 7.6.
            </p>
            <p>
12.5. Termination of these Terms does not affect either party’s rights and obligations that accrued
before that termination.
            </p>
            <p>
12.6. On termination of these Terms, you must pay all Fees for the provision of the Service prior
to that termination.
            </p>
            <p>
12.7. No compensation is payable by us to you as a result of termination of these Terms for
whatever reason, and you will not be entitled to a refund of any Fees that you have already paid.
            </p>
            <p>
12.8. Except to the extent that a party has ongoing rights to use Confidential Information, at the
other party’s request following termination of these Terms but subject to clause 12.9, a party must
promptly return to the other party or destroy all Confidential Information of the other party that
is in the first party’s possession or control.
            </p>
            <p>
12.9. At any time prior to one month after the date of termination, you may request:
            </p>
            <ol type="a">
              <li>
a copy of any Data stored using the Service, provided that you pay our reasonable costs
of providing that copy. On receipt of that request, we must provide a copy of the Data in
a common electronic form. We do not warrant that the format of the Data will be
compatible with any software; and/or
              </li>
              <li>
deletion of the Data stored using the Service, in which case we must use reasonable
efforts to promptly delete that Data.
To avoid doubt, we are not required to comply with clause 12.9a to the extent that you have
previously requested deletion of the Data.
              </li>
            </ol>
            <p>
12.10. Without limiting any other right or remedy available to us, we may restrict or suspend your
access to and use of the Service and/or delete, edit or remove the relevant Data if we consider
that you or any of your personnel have:
            </p>
            <ol type="a">
              <li>
undermined, or attempted to undermine, the security or integrity of the Service or any
Underlying Systems;
              </li>
              <li>
used, or attempted to use, the Service:
                <ol type="i">
                  <li>for improper purposes; or</li>
                  <li>
in a manner, other than for normal operational purposes, that materially reduces
the operational performance of the Service;
                  </li>
                </ol>
              </li>
              <li>
transmitted, inputted or stored any Data that breaches or may breach these Terms or
any third party right (including Intellectual Property Rights and privacy rights), or that is or
may be Objectionable, incorrect or misleading; or
              </li>
              <li>otherwise materially breached these Terms.</li>
            </ol>
          </dd>
          <dt>13. General</dt>
          <dd>
            <p>
13.1. Neither party is liable to the other for any failure to perform its obligations under these
Terms to the extent caused by Force Majeure.
            </p>
            <p>
13.2. No person other than you and us has any right to a benefit under, or to enforce, these Terms.
            </p>
            <p>
13.3. For us to waive a right under these Terms, that waiver must be in writing and signed by us.
            </p>
13.4. Subject to clause 6.4, we are your independent contractor, and no other relationship (e.g.
joint venture, agency, trust or partnership) exists under these Terms.
            <p>
13.5. If we need to contact you, we may do so by email or by posting a notice on the Website. You
agree that this satisfies all legal requirements in relation to written communications. You may
give notice to us under or in connection with these Terms by emailing
accounts@squirreltechnologies.nz.
            </p>
            <p>
13.6. These Terms, and any dispute relating to these Terms or the Service, are governed by and
must be interpreted in accordance with the laws of New Zealand. Each party submits to the
non-exclusive jurisdiction of the Courts of New Zealand in relation to any dispute connected
with these Terms or the Service.
            </p>
13.7. Clauses which, by their nature, are intended to survive termination of these Terms, including
clauses 6.7, 8, 9, 11, 12.5 to 12.9 and 13.6, continue in force.
            <p>
13.8. If any part or provision of these Terms is or becomes illegal, unenforceable, or invalid,
that part or provision is deemed to be modified to the extent required to remedy the illegality,
unenforceability or invalidity. If modification is not possible, the part or provision must be
treated for all purposes as severed from these Terms. The remainder of these Terms will be
binding on you.
            </p>
            <p>
13.9. Subject to clauses 2.1 and 7.6, any variation to these Terms must be in writing and signed by
both parties.
            </p>
            <p>
13.10. These Terms set out everything agreed by the parties relating to the Service, and supersede
and cancel anything discussed, exchanged or agreed prior to the Start Date. The parties have
not relied on any representation, warranty or agreement relating to the Service that is not
expressly set out in these Terms, and no such representation, warranty or agreement has any
effect from the Start Date. Without limiting the previous sentence, the parties agree to contract
out of sections 9, 12A and 13 of the Fair Trading Act 1986, and that it is fair and reasonable
that the parties are bound by this clause 13.10.
            </p>
            <p>
13.11. You may not assign, novate, subcontract or transfer any right or obligation under these Terms
without our prior written consent, that consent not to be unreasonably withheld. You remain
liable for your obligations under these Terms despite any approved assignment, subcontracting
or transfer.
            </p>
          </dd>
        </dl>
        <aside class="uk-placeholder uk-text-muted">
          <h4>Something amiss?</h4>
          <p class="uk-margin-large-left uk-text-right">
Is something here unclear, or otherwise concerning? We would love to hear about it
from you by email at
<a href="mailto:terms-of-service@squirreltechnologies.nz?Subject=About%20your%20terms"
    target="_top">
    terms-of-service@squirreltechnologies.nz
</a>
          </p>
          <p class="uk-margin-large-left uk-text-right">
We are a young company, but we are committed to maintaining your privacy. We believe technology
should support and encourage both individuals and businesses, so we would appreciate being made
aware of any changes we can make to this document to further these aims.
          </p>
        </aside>
      </section>
      <SiteFooter class="uk-flex-none uk-flex-bottom"></SiteFooter>
    </article>
  </Lock>
</template>

<script>
import Lock from '@/components/Lock.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import SiteFooter from '@/components/SiteFooter.vue';

export default {
  name: 'termsofservice',
  props: {},
  components: {
    Lock,
    SiteHeader,
    SiteFooter,
  },
};
</script>
